window.NREUM || (NREUM = {});
NREUM.init = {
  distributed_tracing: {
    enabled: true,
  },
  privacy: {
    cookies_enabled: true,
  },
  ajax: {
    deny_list: ['bam.nr-data.net'],
  },
};

NREUM.loader_config = {
  accountID: '3045136',
  trustKey: '3045136',
  agentID: '1834879473',
  licenseKey: 'NRJS-3b510073ac976a2ce53',
  applicationID: '1811226587',
};
NREUM.info = {
  beacon: 'bam.nr-data.net',
  errorBeacon: 'bam.nr-data.net',
  licenseKey: 'NRJS-3b510073ac976a2ce53',
  applicationID: '1811226587',
  sa: 1,
}; /*! For license information please see nr-loader-spa-1.245.0.min.js.LICENSE.txt */
